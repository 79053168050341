import { Analytics } from "analytics";
import googleTagManagerPlugin from "@analytics/google-tag-manager";
import facebookPixelPlugin from "./plugins/facebookPixelPlugin";
import getCookies from "utils/getCookies";

export const buildConfig = (initializedPlugins = []) => {
  const {
    "facebook-pixel": facebookPixel,
    "google-tag-manager": googleTagManager,
  } = {
    "facebook-pixel": {
      pixelId: "1058769488071808",
      autoConfig: true,
    },
    "google-tag-manager": { containerId: "GTM-ND23GWB" },
  };

  const defaultConfig = {
    enabled: true,
    debug: process.env.NODE_ENV === "development",
    testMode: process.env.NODE_ENV === "development",
  };
  const cookies = getCookies();
  let advancedMatching = {};
  if (cookies.uuid) {
    advancedMatching["external_id"] = cookies.uuid;
  }

  const plugins = [...initializedPlugins];
  if (facebookPixel) {
    plugins.push(
      facebookPixelPlugin({
        ...defaultConfig,
        ...facebookPixel,
        advancedMatching,
      })
    );
  }
  if (googleTagManager) {
    plugins.push(
      googleTagManagerPlugin({
        ...defaultConfig,
        ...googleTagManager,
      })
    );
  }

  const analyticsConfig = {
    app: "bucknerprosthetics.com",
    debug: process.env.NODE_ENV === "development",
    plugins,
  };

  return { analyticsConfig };
};

const generateAnalytics = (initializedPlugins) => {
  const { analyticsConfig } = buildConfig(initializedPlugins);
  return Analytics(analyticsConfig);
};

const _analytics = generateAnalytics();

export default _analytics;
